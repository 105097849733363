<template>

  <v-dialog
    v-model="dialog"
    eager
    scrollable
    persistent max-width="600px"
    @keydown.esc="dialog = isDirty()">

    <template v-slot:activator="{ on: dialog }">

      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            fab
            x-small
            v-on="{ ...dialog, ...tooltip }">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>New Channel</span>
      </v-tooltip>
    </template>

    <v-form v-model="isValid" ref="form" @submit.prevent="save">

      <v-card>

        <v-card-title class="headline">
          New Channel
        </v-card-title>

        <v-divider />

        <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Name"
                  outlined
                  clearable
                  inputmode="text"
                  v-model="channel.name"
                  :rules="[rules.required,rules.min(2)]"
                  hint="Required"
                  persistent-hint
                  :background-color="isFieldDirty('name') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  outlined
                  clearable
                  inputmode="email"
                  v-model="channel.email"
                  :rules="[rules.emailOrEmpty]"
                  :background-color="isFieldDirty('email') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Phone"
                  inputmode="tel"
                  outlined
                  clearable
                  v-model="channel.phone"
                  :background-color="isFieldDirty('phone') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Address"
                  inputmode="text"
                  outlined
                  clearable
                  v-model="channel.address"
                  :background-color="isFieldDirty('address') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="8">
                <v-text-field
                  label="City"
                  inputmode="text"
                  outlined
                  clearable
                  v-model="channel.city"
                  :background-color="isFieldDirty('city') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Zip"
                  inputmode="numeric"
                  outlined
                  clearable
                  v-model="channel.zip"
                  :background-color="isFieldDirty('zip') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="12">
                <!--<v-text-field-->
                  <!--label="Country"-->
                  <!--outlined-->
                  <!--clearable-->
                  <!--v-model="channel.country"-->
                  <!--:background-color="isFieldDirty('country') ? '#faf9f5' : ''"-->
                  <!--/>-->
                <v-autocomplete
                  v-model="channel.country"
                  :items="countries"
                  item-text="name"
                  item-value="id"
                  label="Country"
                  outlined
                  :background-color="isFieldDirty('country') ? '#faf9f5' : ''">
                </v-autocomplete>
              </v-col>
              <!--<v-col cols="12">-->
                <!--<v-textarea-->
                  <!--label="Comments"-->
                  <!--outlined-->
                  <!--clearable-->
                  <!--v-model="contact.comments"-->
                  <!--:background-color="isFieldDirty('email') ? '#faf9f5' : ''"-->
                  <!--/>-->
              <!--</v-col>-->
            </v-row>

            <v-alert v-if="error" type="error">
              {{ error }}
            </v-alert>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer v-if="!$vuetify.breakpoint.xsOnly" />

          <v-btn text width="72" @click="dialog = false;">Cancel</v-btn>

          <v-spacer v-if="$vuetify.breakpoint.xsOnly" />

          <v-btn v-if="saving" width="72" text disabled>
            <v-progress-circular indeterminate color="primary" />
          </v-btn>

          <v-btn v-else text color="primary" type="submit" :disabled="!canSave" :loading="saving">Create</v-btn>
        </v-card-actions>

      </v-card>

    </v-form>

  </v-dialog>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'
import ct from 'countries-and-timezones'

export default {
  props: ['clientId'],

  data() {
    return {
      dialog: false,
      isValid: false,
      saving: false,
      error: undefined,

      countries: Object.values(ct.getAllCountries()),

      channel: {},

      rules: {
        emailOrEmpty: v => (v || '').trim().length == 0 || /.+@.+\..+/.test(v) || 'E-mail must be valid',
        required: value => !!value || 'Required',
        min(len) { return v => v && v.length >= len || `Minimum ${len} characters` },
        phoneOrEmpty(len) { return v => {
          v = (v?.replace(/\D/g,'') || '').trim()
          return v.length == 0 || (v && v.length >= len) || `Minimum ${len} characters`
        } },
      },
    }
  },

  computed: {
    ...mapGetters('Client', ['channelById']),

    canSave() { return this.isDirty() && this.isValid },
  },

  methods: {
    ...mapActions('Channel', ['addChannel']),

    initData() {
      this.saving = false
      this.error = undefined

      this.channel = {}
      this.$refs.form.reset()
    },

    isFieldDirty(name) {
      return this.channel[name]?.toString().trim() != ''
    },

    isDirty() {
      const channel = _.pickBy(this.channel, _.identity)
      const isDirty = Object.keys(channel).length > 0
      return isDirty
    },

    async save() {

      if (!this.canSave) return

      this.saving = true

      this.channel.country = this.channel.country?.id

      this.addChannel({...this.channel, active: true})

      .then(addedChannel => {
        this.saving = false
        this.dialog = false
        this.$router.push({name: 'channel', params: { channel_id: addedChannel.id }})
      })

      .catch(error => {
        this.error = error?.response?.data ?? error?.message
        this.saving = false
      })
    }
  },

  watch: {
    dialog: {
      handler() { if (this.dialog) this.initData() },
      immediate: true,
    },

    //channel: {
    //  handler(newValue, oldValue) {
    //    if (newValue.active != oldValue.active) {
    //      this.channel.inactive = !this.channel.active
    //    }
    //    else {
    //      this.channel.active = !this.channel.inactive
    //    }
    //  },
    //  deep: true,
    //},
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-card__title {
    background-color: var(--v-primary-base);
    color: var(--v-headerText-base);
  }
  .v-input--is-label-active label {
    font-weight: bold !important;
    color: var(--v-primary-base);
  }
}
</style>
