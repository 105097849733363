<template>

  <v-container fluid class="ma-0 pa-0" fill-height>

    <splitpanes
      v-if="this.$vuetify.breakpoint.mdAndUp || this.$route.name == 'applications'" 
      class="default-theme">

      <pane size="30">
        <v-list
          class="mx-4"
          v-model="applicationSelectedIndex">

          <v-list-item class="pt-0 px-2">
            <NewChannelDialog />

            <v-spacer />

          </v-list-item>

          <v-list-item class="pa-0">
            <v-text-field
              dense
              hide-details
              outlined
              clearable
              label="Name, Address, Email, Phone"
              v-model="applicationFilter"
              @click:clear="clearApplicationFilter"
              class="mt-2"
              style="border-bottom-left-radius: 0px; border-bottom-right-radius: 0px;"
            />
          </v-list-item>

          <v-list-item
            v-for="item in filteredApplicationList"
            :key="item.id"
            link
            :to="{name: 'application', params: { application_id: item.id }}">

            <v-list-item-content>
              <v-list-item-title>
                {{ item.first_name }} {{ item.middle_name }} {{ item.last_name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <template v-if="item.country">
                  {{ countryName(item.country) }}
                </template>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <template v-if="item.networks_count">
                Networks {{ item.networks_count }}
                </template>
                <template v-else>
                  <em>No Networks</em>
                </template>

                &middot;

                <template v-if="item.terminals_count">
                Terminals {{ item.terminals_count }}
                </template>
                <template v-else>
                  <em>No Terminals</em>
                </template>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-icon class="pr-2">
              <!-- <v-icon :style="{visibility: item.active ? 'hidden' : 'visible'}" color="red" >mdi-minus-circle-outline</v-icon> -->

              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </pane>
      <!-- </div> -->

      <pane>
      <!-- <div                                        -->
      <!--   class="mt-sm-1 ml-sm-1"                   -->
      <!--   v-if="$vuetify.breakpoint.mdAndUp"        -->
      <!--   style="grid-column: 2; overflow: scroll;" -->
      <!--   >                                         -->
        <router-view />
      </pane>
      <!-- </div> -->
    </splitpanes>
    <!-- </div> -->

    <router-view v-else />

  </v-container>

</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import PhoneNumber from 'awesome-phonenumber'
import NewChannelDialog from '@/components/NewChannelDialog.vue'
import ct from 'countries-and-timezones'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

export default {
  data() {
    return {
      applicationFilter: '',
      applicationSelectedIndex: undefined,
      applicationSelected: 0,
      filteredApplicationList: [],
      applicationId: undefined,

      newApplicationDialog: false,
    }
  },

  metaInfo() {
    return {
      title: 'Applications',
    }
  },

  computed: {
    ...mapGetters('Application', ['applications'])
  },

  components: {
    NewChannelDialog,
    Splitpanes,
    Pane
  },

  methods: {
    ...mapActions('Application', [ 'getApplicationList', ]),

    countryName(code) {
      return ct.getCountry(code)?.name
    },

    formatPhone: (phone) => phone ? PhoneNumber(phone.replace(/\D/gi, ''), 'US').getNumber('national') : undefined,

    clearApplicationFilter() {
      this.applicationFilter = ''
    },

    filterApplicationList() {
      const filter = this.applicationFilter?.toLowerCase() ?? ''
      const applications = this.applications

      const filteredApplicationList = filter.length == 0
        ? applications
        : applications.filter(application =>
            application.first_name?.toLowerCase().includes(filter) ||
            application.last_name?.toLowerCase().includes(filter) ||
            application.settlement?.toLowerCase().includes(filter) ||
            application.island?.toLowerCase().includes(filter) ||
            application.date_of_birth?.toLowerCase().includes(filter) ||
            application.phone?.toLowerCase().includes(filter) ||
            application.email?.toLowerCase().includes(filter)
          )

      this.filteredApplicationList = [...filteredApplicationList]
    },
  },

  mounted() {
    this.getApplicationList()
  },

  watch: {

    applications: {
      handler() {
        this.filterApplicationList()
      },
      immediate: true,
    },

    applicationFilter: {
      handler() { this.filterApplicationList() },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.splitpanes.default-theme .splitpanes__pane {
  background-color: inherit !important;
}

::v-deep {
  .v-badge--inline .v-badge__badge {
    background-color: inherit !important;
    width: 0.7em;

    &:after {
    border-color: gray;
  }
  }
}
</style>
